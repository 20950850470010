import React from 'react';
import {Link} from "react-router-dom";

const About = () => (
    <section>
        <div className="container">
            <h2>Archery knowledge base</h2>
            <h4>Common terms and abbreviations used in archery (chapter WIP)</h4>
            <br />
            <p>
                <b>GPP ("Grains Per Pound")</b> - одна із характеристик стріли. Це єдина міра маси, яка часто використовується в стрільбі з лука, щоб описати масу стріл.
                <br />
                Визначається співвідношенням ваги стріли до сили натягу лука.
                <br />
                1 фунт еквівалентний приблизно 7000 гранам (зернам). Чим більше GPP має стріла, тим більша її маса. Маса стріли має великий вплив на енергію, яку вона може перенести на ціль, а також на її траєкторію польоту.
                <br />
                Деякі лучники використовують більш важкі стріли (вищий GPP) для полювання, щоб забезпечити більший удар і глибше проникнення. Інші можуть використовувати легші стріли (нижчий GPP) для того, щоб забезпечити більшу швидкість і плоску траєкторію польоту для стрільби на далекі відстані, наприклад, під час змагань зі стрільби з лука.
            </p>
            <p>
                <b>GPI ("Grains Per Inch")</b> - інша характеристика стріли, визначається співвідношенням ваги стріли до її довжини
            </p>
            <hr/>
            Стосовно побажань по функціоналу додатку (чи іншим питанням) писати на <Link to="mailto:serhii.fedirko@gmail.com">serhii.fedirko@gmail.com</Link>
            <hr/>
        </div>
    </section>
);

export default About;